import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { navigate } from 'gatsby'
import {
  Anchor,
  useAuth,
  getAuth,
  useResolution,
  TextField,
  Button,
  Error,
  InfoMessage,
  breakpoints,
  useShoppingCart,
} from '@ecommerce/shared'
import { Icon } from '../../components/Icon/Icon'
import Layout from '../../components/Layout'
import { PageContainer } from '../../components/PageContainer'
import { PgPageProps } from '../../types/PgPages'
import { UserMenuMobileNavbar } from '../../components/NavBar/UserMenuMobileNavbar'
import withPageTransition from '../../components/withPageTransition'
import { sendMessageToSentry, ErrorLevel, ErrorSource } from '../../utils/sentry'
import { sendPageViewEvent } from '../../utils/events'

const cssPrefix = `NewPassword__`

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .${cssPrefix} {
    &top-bar {
      text-align: center;
      margin-bottom: 27px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      &-icon {
        width: 30px;
        cursor: pointer;
      }
      &-title {
        text-align: center;
        flex-grow: 1;
        @media (${breakpoints.desktop.min}) {
          padding-right: 30px;
        }
      }
    }
    &container {
      width: 310px;
      display: flex;
      flex-direction: column;
      .${cssPrefix} {
        &form {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-current-password {
            margin-bottom: 30px;
          }
          &-new-password {
            margin-bottom: 20px;
          }
          &-confirm-new-password {
            margin-bottom: 80px;
          }
          &-submit-button {
            width: 250px;
            margin-bottom: 15px;
          }
        }
        &status-error {
          display: flex;
          justify-content: center;
          width: 100%;
          &-message {
            width: 250px;
            color: ${(props) => props.theme.colors.error};
            svg {
              fill: ${(props) => props.theme.colors.error};
            }
          }
        }
      }
    }
  }
`

interface FormData {
  password: string
  newPassword: string
  confirmNewPassword: string
}

const schema = Yup.object().shape({
  password: Yup.string().required('Debes completar este campo'),
  newPassword: Yup.string()
    .required('Debes completar este campo')
    .min(8, 'Mínimo 8 caracteres')
    .test('password-match', 'Las contraseñas no coinciden', function (val) {
      return this.parent.confirmNewPassword === val
    }),
  confirmNewPassword: Yup.string()
    .required('Debes completar este campo')
    .min(8, 'Mínimo 8 caracteres')
    .test('password-match', 'Las contraseñas no coinciden', function (val) {
      return this.parent.newPassword === val
    }),
})

const NewPassword = (props: PgPageProps) => {
  const { getConnectifCart } = useShoppingCart()
  const { state: authState, onSignIn, onCustomerUpdate, getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Nueva contraseña' })
  }, [])

  const { email } = authState
  const isAuth = getAuth()
  useEffect(() => {
    if (!isAuth) navigate(`/`)
  }, [])
  const { isDesktop } = useResolution()

  const [authError, setAuthError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })
  const watchFields = watch()
  const errorMessage = authError?.description
  const isSubmitDisabled = !watchFields.password || !watchFields.newPassword || !watchFields.confirmNewPassword

  useEffect(() => {
    errors.password = undefined
    errors.newPassword = undefined
    errors.confirmNewPassword = undefined
    Object.keys(errors)
  }, [watchFields])

  const onSubmit = async (form: FormData) => {
    if (isLoading) return
    setIsLoading(true)
    onSignIn({ username: email, password: form.password })
      .then(() => {
        onCustomerUpdate({ ...authState, newPassword: form.newPassword }).then(() => {
          setIsLoading(false)
          navigate(`/new-password/success`)
        })
      })
      .catch((error) => {
        if (error.message === 'bloqued') {
          sendMessageToSentry({
            message: `On new-password: usuario bloqueado`,
            page: window.location.pathname,
            source: ErrorSource.CLayer,
            level: ErrorLevel.Error,
            metadata: { payload: { email }, error },
          })
        } else {
          if (error.code === 'INVALID_GRANT') setAuthError({ ...error, description: 'Contraseña Incorrecta' })
          else setAuthError(error)
          setIsLoading(false)
        }
      })
  }

  return (
    <Layout title="Cambiar contraseña" navbar={isDesktop ? undefined : UserMenuMobileNavbar({})}>
      <PageContainer cardMaxWidthDesktop="723px" cardWidthDesktop="723px" cardMinWidthDesktop="723px">
        <PageWrapper>
          <div className={`${cssPrefix}top-bar`}>
            {isDesktop && (
              <Anchor onClick={() => navigate(`/my-account`)} className={`${cssPrefix}top-bar-icon`}>
                <Icon iconId="arrow_left" size="24" />
              </Anchor>
            )}
            <h2 className={`${cssPrefix}top-bar-title`}>Cambiar contraseña</h2>
          </div>
          <div className={`${cssPrefix}container`}>
            <form className={`${cssPrefix}form`} onSubmit={handleSubmit(onSubmit)}>
              <TextField
                name="password"
                data-test="password-field"
                className={`${cssPrefix}form-current-password`}
                placeholder="Contraseña actual"
                type="password"
                status={!errors.password ? undefined : 'error'}
                errorMessage={errors.password?.message}
                ref={register}
                disabled={isLoading}
              />
              <TextField
                name="newPassword"
                data-test="new-password-field"
                className={`${cssPrefix}form-new-password`}
                placeholder="Nueva contraseña"
                type="password"
                helpMessage="Mínimo 8 caracteres"
                status={!errors.newPassword ? undefined : 'error'}
                errorMessage={errors.newPassword?.message}
                ref={register}
                disabled={isLoading}
              />
              <TextField
                name="confirmNewPassword"
                data-test="confirm-new-password-field"
                className={`${cssPrefix}form-confirm-new-password`}
                placeholder="Repite la nueva contraseña"
                type="password"
                helpMessage="Mínimo 8 caracteres"
                status={!errors.confirmNewPassword ? undefined : 'error'}
                errorMessage={errors.confirmNewPassword?.message}
                ref={register}
                disabled={isLoading}
              />
              <Button
                isDisabled={isSubmitDisabled}
                disabled={isLoading || isSubmitDisabled}
                type="submit"
                isLoading={isLoading}
                className={`${cssPrefix}form-submit-button`}
              >
                Continuar
              </Button>
            </form>
            <div className={`${cssPrefix}status-error`}>
              <InfoMessage
                className={`${cssPrefix}status-error-message`}
                isHidden={!errorMessage}
                message={errorMessage}
              />
            </div>
          </div>
        </PageWrapper>
      </PageContainer>
    </Layout>
  )
}

export default withPageTransition(NewPassword)
